<template>
    <PageContent>
        <PageTitle>Waste Tracking</PageTitle>
        <NotReady v-if="!ready" />
        <div v-else>
            <div class="container pt-1 pb-1 mb-3 bg-light border">
                <div class="row">
                    <div class="col text-center">
                        <a class="btn btn-primary btn-sm m-1" href="/waste/">Enter</a>
                        <a class="btn btn-primary btn-sm m-1" href="/waste/review">Review</a>
                        <button class="btn btn-primary btn-sm m-1"  @click.prevent.stop="Post">Post</button>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class=" col-xl-6 offset-xl-3 border p-3">
                        <form @submit.prevent.stop="Save">
                            <fieldset :disabled="disabled">
                                <div class="form-group">
                                    <label>Location</label>
                                    <select class="form-control" v-model="txtLocation" name="txtLocation">
                                        <option value="">Select Location</option>
                                        <option v-for="(location,idx) in locations" :key="idx" :value="location">
                                            {{location['Route No_']}} {{location['Location No_']}} {{location['Name']}}
                                        </option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <label>Date</label>
                                    <input autocomplete="off" autosuggest="off" type="text"  v-model="txtDate" name="txtDate" class="form-control datepicker" readonly>
                                </div>
                                <div class="form-group">
                                    <label>Item</label>
                                    <input autocomplete="off" maxlength="20" autosuggest="off" type="text"  v-model="txtItem1" name="txtItem1" class="form-control" @keydown="autoSubmit($event)" autofocus>
                                </div>
                                <div class="form-group">
                                    <label>Qty</label>
                                    <input autocomplete="off" autosuggest="off" type="number" v-model="txtQty" name="txtQty" class="form-control" maxlength="2">
                                </div>
                                <div class="mt-3 d-inline-block">
                                    <label>Show History</label>    
                                    <div class="form-check form-switch">
                                        <input autocomplete="off" autosuggest="off" class="form-check-input" name="chkHistory" type="checkbox" v-model="chkHistory" id="chkHistory" true-value="ON" false-value="OFF">
                                    </div>
                                </div>
                                <div class="mt-3 text-center">
                                    <button class="btn btn-primary" :disabled="!txtLocation">Save</button>
                                </div>
                                <hr>
                            </fieldset>
                        </form>
                        <div class="text-center"><small><b>Note:</b> When scanning cursor must be in item field.</small></div>
                    </div>
                    <div class="col-12 mt-3" v-if="chkHistory=='ON'">
                        <table :class="`table table-bordered table-striped table-lg min-w-auto`"  :style="`table-layout:fixed;`">
                            <thead>
                                <tr>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">Location</th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">Item</th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">Qty</th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">Tran Date</th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">DEL</th>
                                </tr>
                                
                            </thead>
                            <tfoot>
                                <tr>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);"><b>Total</b></th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);">{{ _.sumBy(history, h=>parseInt(h.qty)) }}</th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                                    <th class="text-white" style=" background-color: var(--bs-gray-700);"></th>
                                </tr>
                            </tfoot>
                            <tbody>
                                <tr v-for="(row, idx) in _.orderBy(history, h=>h.sessionid&&h.date.date, ['desc','desc'])" :key="idx" v-if="history.length">
                                    <td class="text-start">{{row['location']}}</td>
                                    <td class="text-start">{{ row['item'] }} {{ row['description'] }}</td>
                                    <td class="text-start">{{ parseInt(row.qty) }}</td>
                                    <td class="text-start">{{ $root.moment(row.date.date).format('MM/DD/YYYY') }}</td>
                                    <td class="text-start"><a href="#" @click.prevent.stop="Delete(row)" class="text-danger fa-solid fa-trash"></a></td>
                                </tr>
                            </tbody>
                        </table> 
                    </div>
                    <div v-else>
                        
                    </div>
                </div>
            </div>
        </div>
    </PageContent>
  </template>
  <script>
    import Modal from '@/components/Modal.vue';
    import PageContent from '@/components/PageContent.vue';
    import NotReady from '@/components/NotReady.vue';
    import PageTitle from '@/components/PageTitle.vue';
    import Table from '@/components/Table/Table.vue';
    import TableRow from '@/components/Table/TableRow.vue';
    import TableHead from '@/components/Table/TableHead.vue';
    import TableCell from '@/components/Table/TableCell.vue';
    import VueSimpleAlert from 'vue3-simple-alert';
    import axios from 'axios';

  export default {
    name: 'Waste',
    components: {Modal, PageContent, PageTitle, Table, TableRow, TableCell, NotReady, TableHead},
    watch:{
        chkHistory:async function(newVal){
            if(newVal=='ON'){
                this.history = await this.getHistory();
            }
            else{
                this.history = [];
            }
        }
    },
    data(){
        return {
            _:_,
            ready:false,
            disabled:false,
            locations:[],
            txtQty:1,
            txtItem1:'',
            txtDate:this.$root.moment().format('MM/DD/YYYY'),
            txtLocation:'',
            chkHistory:'ON',
            history:[],
        }
    },
    async mounted(){
        let res = await axios.get(`${this.$store.state.api}/custlocations?lccustno=${this.$root.getCustomerNumber()}`);
        this.locations = res;
        this.locations = _.sortBy(this.locations, ['Route No_','Location No_']);
        this.ready = true;
        if(this.chkHistory=='ON') this.history = await this.getHistory();
        await  Sleep(10);
        $('.datepicker').datepicker({
            onSelect:(date)=>{
                this.txtDate = date;
            }
        });

    },
    methods:{
        async autoSubmit(e){
            if(e.key=='Enter'){
                this.Save(true)
                e.preventDefault();
            }
        },
        async getHistory(){
            return new Promise(async resolve=>{
                let res;
                let formData = new FormData();
                formData.append('waste', JSON.stringify({
                    txtLocation:JSON.stringify(this.txtLocation),
                    txtDate:this.txtDate,
                    txtQty:this.txtQty,
                    txtItem:this.txtItem1,
                    chkHistory:this.chkHistory,
                    cnt:15,
                }));
                res = await axios.post(`${this.$store.state.api}/wastedisplay?lccustno=${this.$root.getCustomerNumber()}&BTNSUBMIT=Save&session_id=${localStorage.sessionId}&cnt=15`, formData);
                resolve(res);
            });
        },
        Save:_.debounce(async function(skipLocation=false){
            // this.disabled = true;
            console.log(this.txtItem1)
            if(!skipLocation) return;
            let req, res;
            let formData = new FormData();
            formData.append('waste', JSON.stringify({
                txtLocation:JSON.stringify(this.txtLocation),
                txtDate:this.txtDate,
                txtQty:this.txtQty,
                txtItem:this.txtItem1,
                chkHistory:this.chkHistory
            }));

            res = await axios.post(`${this.$store.state.api}/waste?lccustno=${this.$root.getCustomerNumber()}&BTNSUBMIT=Save&session_id=${localStorage.sessionId}&cnt=15`, formData);
            
            if(res.message!=undefined){
                if(res.message=='error'){
                    this.disabled=false;
                    await Sleep(5);
                    $('input[name="txtItem1"]').focus();
                    this.txtItem1 = '';

                    return; 
                }
            }
            if(res.lclocation!=undefined){
                this.txtDate = res['lcdate'];
                this.locations.forEach(l=>{
                    if(l['Location No_']==res['lclocation']){
                        this.txtLocation = l;
                    }
                })
            }
            this.disabled=false;
            await Sleep(5);
            this.txtItem1 = '';
            this.txtQty = 1;
            $('input[name="txtItem1"]').focus();
            if(this.chkHistory=='ON'){
                this.history = res;
            }
        }, 300, {leading:true, trailing:false}),
        Delete:_.debounce(async function(item){
            let req, res;
            let formData = new FormData();
            req = await axios.get(`${this.$store.state.api}/wastereview?lccustno=${this.$root.getCustomerNumber()}&lctransid=${item.tranid}`);
            this.history = await this.getHistory();
            if(this.history.length==0) this.history = [];
        }, 300, {leading:true, trailing:false}),
        Post:_.debounce(async function(){
            let res;
            let formData = new FormData();
            res = await axios.get(`${this.$store.state.api}/wastepost?lccustno=${this.$root.getCustomerNumber()}`);
            if(res.message!=undefined&&res.message=='success') this.history = [];
        }, 300, {leading:true, trailing:false})
    },
  }
  </script>
  <style lang="scss" scoped>
    table{
      font-size: .875em !important;
      thead, tbody{
        td, th{}
          font-size: .875em !important;
        }
    }
  </style>